@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;700&family=Ubuntu&display=swap');

/* html reset */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-family: var(--body-font), sans-serif;
}

p,
h1,
h2,
h3 {
  font-family: var(--body-font), sans-serif;
}

fieldset {
  border: 1px solid var(--border-gray);
  padding: 15px 20px;
  border-radius: 10px;
}

legend {
  background-color: var(--legend-bg);
  color: var(--legend-color);
  font-weight: bold;
  padding: 4px 10px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  font-size: 15px;
}


/* thin scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, .35);
}

* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

*:hover {
  scrollbar-color: rgba(120, 120, 120, .35) transparent;
}

/* flexbox stuff */
.flex {
  display: flex;
  align-items: flex-start;
}

.alignStart {
  align-items: flex-start;
}

.grow {
  flex: 1;
}

.justifyCenter {
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.flexCol {
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}

.noWrap {
  flex-wrap: nowrap;
}

.noShrink {
  flex-shrink: 0;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.alignSelfEnd {
  align-self: flex-end;
}

.alignSelfStart {
  align-self: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

/* layout */
.fullWidth {
  width: 100%;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative;
}

.bold {
  font-weight: bold;
}

.textAlignCenter {
  text-align: center;
}

.fullHeight {
  height: 100%;
}

.truncatedOneLine,
.truncatedTwoLine {
  display: -webkit-box !important;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.truncatedOneLine {
  -webkit-line-clamp: 1;
}

.truncatedTwoLine {
  -webkit-line-clamp: 2;
}

.clickable {
  cursor: pointer;
}

.displayNone {
  display: none;
}

.resizeVertical textarea {
  resize: vertical !important;
}

/* containers */
.primaryCard {
  background-color: var(--primary-card-bg);
}

.secondaryCard {
  background-color: var(--secondary-card-bg);
}

.container-md {
  width: 40%;
  min-width: 500px;
}

.container-md .input {
  width: 100%;
}

.outline {
  border: 2px solid var(--border-light);
  border-radius: 10px;
  padding: 5px 10px;
}

/* Button */
.iconButton {
  padding: 5px;
  border-radius: 50% !important;
  width: 35px;
  height: 35px;
}

@media screen and (max-width: 940px) {
  button.noTextOnSmallScreen [class*='mantine-Button-label'] {
    display: none;
  }

  button.noTextOnSmallScreen [class*='mantine-Button-icon'] {
    margin-right: 0;
  }

  button.noTextOnSmallScreen {
    padding-right: 12px;
  }
}

/* Animations */
@keyframes slidedown {
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.slideDown {
  transform: scaleY(0);
  opacity: 0;
  transform-origin: 50% 0%;
  animation: slidedown 0.2s forwards ease-out;
}

@keyframes slideup {
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.slideUp {
  transform: scaleY(1.5);
  opacity: 0;
  transform-origin: 50% 0%;
  animation: slidedown 0.2s forwards ease-in;
}