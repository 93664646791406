.categoryGridContainer {
  container-type: inline-size;
  container-name: panel;
}

.categoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(33% - 40px));
  gap: 20px;
  justify-content: flex-start;
}

@container panel (max-width: 980px) {
  .categoryGrid {
    grid-template-columns: repeat(auto-fit, calc(50% - 20px));
  }
}

@container panel (max-width: 640px) {
  .categoryGrid {
    grid-template-columns: 1fr;
  }
}

.scoreAndTimer {
  width: 320px;
}

.team.currentTeam {
  background: rgb(34, 139, 230);
  flex-wrap: nowrap;
}

.team.currentTeam div {
  color: white;
}

.team {
  background: rgba(134, 142, 150, 0.1);
}

.teamWithoutPlayer {
  padding: 3px 15px 3px 3px;
  border-radius: 58px;
}

.winnerMessage {
  background-color: var(--qm-primary);
  border-radius: 10px;
  position: relative;
}

.winnerMessage::before {
  display: block;
  position: absolute;
  content: "";
  width: calc(100% + 16px);
  margin-left: -8px;
  z-index: -1;
  height: 78px;
  margin-top: -24px;
  border-radius: 10px;
  background: repeating-linear-gradient(45deg, var(--correct-color) 1%, var(--qm-primary) 3%);
  animation: borderRoll 0.5s linear infinite;
}

@keyframes borderRoll {
  50% {
    background: repeating-linear-gradient(45deg, var(--correct-color) 2%, var(--qm-primary) 4%);
  }

  to {
    background: repeating-linear-gradient(45deg, var(--correct-color) 3%, var(--qm-primary) 5%);
  }
}

.extraQuestionsBanner {
  background-color: var(--default-button-bg);
  border-radius: 10px;
  position: relative;
}

.closeBtn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  left: auto;
}

.colorPallet {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.colorPallet .bar {
  width: 200px;
  height: 35px;
  background: linear-gradient(to right, var(--points-color-1), var(--points-color-2), var(--points-color-3), var(--points-color-4), var(--points-color-5));
  margin: 0 10px;
}