.avatar {
  border-radius: 32px;
  width: 38px;
  height: 38px;
  font-weight: 700;
  color: white;
  flex-shrink: 0;
}

.avatar.small {
  border-radius: 28px;
  width: 28px;
  height: 28px;
  font-weight: 700;
  font-size: 12px;
  color: white;
}