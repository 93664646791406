label.questionText,
label.optionText,
label.correctAns {
  font-size: 16px !important;
}

label.questionText,
label.correctAns {
  width: 100%;
}

.questionText textarea,
.optionText textarea,
.correctAns textarea {
  font-size: 18px;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  min-height: 80px;
}

.questionText textarea {
  min-height: 150px;
}

.optionText,
.correctAns {
  max-width: 100%;
  width: calc(100% - 70px);
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.optionText textarea {
  min-height: 30px;
}

.markdownImg {
  vertical-align: middle;
}

.pointsInput {
  width: 75px;
}