.quizCardWrapper {
  width: 20%;
  min-width: 250px;
  height: 320px;
  padding: 10px 16px 16px;
  margin: 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.tileIcon {
  opacity: 0.5;
  padding-top: 10px;
}


.cardSelectBtn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}