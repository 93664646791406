.baseTimer {
  position: relative;
  height: 150px;
  width: 150px;
  margin: 10px auto;
}

/* Removes SVG styling that would hide the time label */
.baseTimerCircle {
  fill: none;
  stroke: none;
}

/* The SVG path that displays the timer's progress */
.baseTimerPathElapsed {
  stroke-width: 7px;
  stroke: grey;
}

.baseTimerLabel {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}

.baseTimerPathRemaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  stroke: currentColor;
}

.baseTimerSvg {
  transform: scaleX(-1);
}

.green {
  color: var(--correct-color);
}

.red {
  color: var(--incorrect-color);
}

.yellow {
  color: var(--warning);
}

.pauseButton {
  position: absolute;
  left: 13px;
  top: 13px;
  border-radius: 50%;
  height: 124px;
  width: 124px;
  background-color: transparent !important;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.pauseButton:hover {
  opacity: 1;
}

.playButton {
  opacity: 1;
}
