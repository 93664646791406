.activeCategory {
  border-right: 0;
  z-index: 1;
  clip-path: inset(-2px 0px -5px -5px);
  right: -1px;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.nonActiveCard {
  background-color: white;
  padding-bottom: 30px;
}

.btnCompleteQuiz {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.wrapper {
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.categoriesList {
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 450px;
}

.questionsListPanel {
  flex-grow: 4;
  max-width: 1300px;
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  height: calc(100vh - 180px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.categoryNameInput input {
  margin-right: 27px;
  font-weight: bold;
  margin-top: -10px;
}

.radio [class*="mantine-Radio-labelWrapper"] {
  flex-grow: 1;
}

.radio {
  align-items: flex-start;
}

.chosenStyle {
  background-color: var(--dragging-question-bg);
}